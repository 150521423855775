import React from "react"
import Log from "./../../../templates/log.js"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import { Caption, Pinout, Table, FirstP, Emoji , Pre} from "./../../../components/helpers.js"

export default ({ data, pageContext }) => {

    const content = <>
        
        <FirstP>Parked in front of my house is an old BMW E36 325i. When I bought it, it came loaded with cassette tapes from the previous owner, like a time capsule from a different era. Although I quite like Johnny Cash and Kenny Rogers, it gets repetitive after a while. So I set myself to work to make my radio Spotify enabled.</FirstP>
        
        <Img fadeIn={false} fluid={data.img1.childImageSharp.fluid} alt="Cassettes" />
        <Caption>These casettes will soon go unused</Caption>

        <p>
            First of all you might think these interfaces already exist, and this is partially true.
            Bluetooth interfaces from companies like Yatour and others are indeed available, but <i>only</i> for head units that support the IBUS protocol.
            There is only one head unit for the E36 that fits the bill, which is the CD43. But probably exactly for this reason, second hand prices for this unit
            are a lot higher than its alternatives.
        </p>

        <p>
            A second route could be to modify the radio itself and for instance solder a Bluetooth module to the audio connections of the tape deck. A drawback of this is that
            it is less straightforward to control your music from the buttons on the radio. Therefore I went with a head unit with a CD Changer interface which I could then use.
            The added benefit of this is that I do not need to open up the radio which makes it a more elegant solution.
        </p>
        
        <p>
            The radio I chose is the BMW Business RDS, also known as the Philips PH7850.
            This head unit can be connected to BMW 6 CD Changer A, and I managed to find a set with the two for a good price.
            Now that I have selected the right head unit there are two main parts to this project. 
            First of all I need to reverse engineer the CD changer interface, and secondly I need to connect this to a bluetooth audio module.
        </p>

        <Img fadeIn={false} fluid={data.img2.childImageSharp.fluid} alt="Radio" />
        <Caption>Philips PH7850 and BMW 6 CD Changer A</Caption>

        <h3>Reverse engineering</h3>
       
        <p>The CD Changer connects to a 10 pin connector below the main radio connector. This connector is very conveniently compatible with standard breadboard jumper wires <Emoji e="😊" />.
            From the little I found online, this protocol is also known as Pioneer M-bus. Therefore this product will most likely work with more period BMW, and even non-BMW head units.
            I also found the pinout of the connector online. Looking at the back of the radio the pinout is:
        </p>

        <Pinout><tbody>
            <tr><td>Audio L -</td><td>Audio R -</td><td>GND</td><td>ENBL</td><td>DATA</td></tr>
            <tr><td>Audio L +</td><td>Audio R +</td><td>GND</td><td>RSET</td><td>CLCK</td></tr>
        </tbody></Pinout>

        <p>
            The magic is happening in the four connections on the right, which make up the data interface. Luckily, the reverse engineering proved less complicated than expected. 
            After hooking up the logic analyzer I quickly found that the interface resembled some form of SPI communication albeit with a single data line. 
            Typically SPI has two data lines, one for each direction. But as it turns out this bi-directional approach also exists and is known as 3-wire SPI.
        </p>

        <Img fadeIn={false} fluid={data.img3.childImageSharp.fluid} alt="Logic Analyzer" />
        <Caption>No secret is safe for my Saleae</Caption>

        <p>The head unit is acting as a master and periodically sending a message to the CD Changer. 
        After the transmission is complete, the CD Changer responds with its current status.
        I did not figure out the meaning of every single byte transmitted, but at least enough to mimick and implement my own interface.
        </p>

        <h3>Communication</h3>

        <p>First there is some kind of handshake. I am not sure what it means but I observed this to be the same every time so I just hardcoded it.
            First the head unit sends <Pre>0x06</Pre> <Pre>0x00</Pre>, to which the CD Changer responds with <Pre>0x60</Pre> <Pre>0x01</Pre> <Pre>0x18</Pre>. 
            Then there is a second handshake which looks quite similar with the head unit sending <Pre>0x06</Pre> <Pre>0x00</Pre> <Pre>0xF6</Pre> and
            the CD Changer responding with <Pre>0x60</Pre> <Pre>0x02</Pre> <Pre>0x18</Pre> <Pre>0x00</Pre>. After the handshake was successfull
            there is a periodic communication according to the tables below. 
            There might be some more functions not listed, but this is at least everything I need for my implementation.
        </p>

        <h3>Head Unit Request</h3>
        <Table><tbody>
            <tr><th style={{ width: '3em' }}>Byte</th><th style={{ width: '7.5em' }}>Value</th><th>Interpretation</th></tr>
            <tr><td>1</td><td><Pre>0x06</Pre></td><td>Constant head unit identifier</td></tr>
            <tr><td>2</td><td><Pre>0x00</Pre></td><td>The head unit is turned off</td></tr>
            <tr><td></td><td><Pre>0x01</Pre></td><td>The head unit is turned on</td></tr>
            <tr><td>3</td><td><Pre>0x00</Pre></td><td>No action</td></tr>
            <tr><td></td><td><Pre>0x26</Pre></td><td>Next track</td></tr>
            <tr><td></td><td><Pre>0x27</Pre></td><td>Previous track</td></tr>
            <tr><td></td><td><Pre>0x31</Pre> - <Pre>0x36</Pre></td><td>CD1 to CD6 button</td></tr>
            <tr><td></td><td><Pre>0x06</Pre></td><td>CD mode entered</td></tr>
            <tr><td></td><td><Pre>0x16</Pre></td><td>CD mode left</td></tr>
        </tbody></Table>

        <h3>CD Changer Response</h3>
        <Table><tbody>
            <tr><th style={{ width: '3em' }}>Byte</th><th style={{width: '7.5em'}}>Value</th><th>Interpretation</th></tr>
            <tr><td>1</td><td><Pre>0x61</Pre></td><td>CD playback</td></tr>
            <tr><td></td><td><Pre>0x60</Pre></td><td>No CD playback</td></tr>
            <tr><td>2</td><td><Pre>0x09</Pre></td><td>?</td></tr>
            <tr><td>3</td><td><Pre>0x08</Pre></td><td>?</td></tr>
            <tr><td>4</td><td><Pre>0x04</Pre></td><td>Other values than <Pre>0x04</Pre> set predefined display texts</td></tr>
            <tr><td>5</td><td><Pre>0xF1</Pre> - <Pre>0xF6</Pre></td><td>F<i>x</i> in which <i>x</i> is the displayed CD number</td></tr>
            <tr><td>6</td><td><Pre>0x00</Pre> - <Pre>0x99</Pre></td><td>Hex value is the playback time in minutes</td></tr>
            <tr><td>7</td><td><Pre>0x00</Pre> - <Pre>0x99</Pre></td><td>Hex value is the playback time in seconds</td></tr>
            <tr><td>8</td><td><Pre>0x00</Pre> - <Pre>0x99</Pre></td><td>Hex value is the active track number</td></tr>
            <tr><td>9</td><td><Pre>0xFF</Pre></td><td>Static value?</td></tr>
            <tr><td>10</td><td><Pre>0x3F</Pre></td><td>Static value?</td></tr>
            <tr><td>11</td><td><Pre>0x00</Pre> - <Pre>0xFF</Pre></td><td>Binary combination of which CD's are present</td></tr>
        </tbody></Table>

        <h3>First Prototype</h3>

        <p>I implemented this communication protocol on an arduino pro mini. And used a breadboard compatible bluetooth module with a CSR8635 chip. 
        With this I do not need to get involved on the Bluetooth side. The module I picked has some pins for play/pause controls and next/previous track.
        I connected these to the Arduino to link them to the actual head unit buttons <Emoji e="👍" />. Finally I used an isolated DC/DC converter to give the
        Bluetooth module its own ground in an attempt to improve the audio quality.</p>

        <Img fadeIn={false} fluid={data.img4.childImageSharp.fluid} alt="First prototype" />
        <Caption>Not the prettiest, but hey, it works!</Caption>

        <p>This module is currently running in my car, and except for some minor issues it is working flawlessly! As a next step I will turn this into a custom PCB design.</p>

        </>;

    return (<Log pageContext={pageContext}>{content}</Log>);
}

export const query = graphql`
{
    img1: file(relativePath: { eq: "cassettes.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img2: file(relativePath: { eq: "radio.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img3: file(relativePath: { eq: "saleae.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }

    img4: file(relativePath: { eq: "prototype.jpg" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
